<template>
  <div class="scheme-contain">
		<div class="data-header">
			<div class="data-date">{{data.xlsj.substring(0, 16)}}</div>
			<div class="data-items">
				<span>训练时长： {{data.sportTime}}min</span>
				<span>消耗能量： {{data.calories}}kcal</span>
				<span>平均心率： {{data.avgHeart}}次/分</span>
				<span>最大心率： {{data.maxHeart}}次/分</span>
			</div>
		</div>
    <div class="data-contain">
			<div class="chart" ref="train"></div>
    </div>
    <div class="symbol-box tac">
			<Button type="primary" size="large" @click="$router.back()">返回</Button>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import echarts from 'echarts';
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			data: {
				xlsj: '',
				avgHeart: '',
				maxHeart: '',
				sportTime: '',
				calories: '',
			},
			dateData: [],
			totalData: [],
			highData: [],
			lowData: [],
			trainData: {
				member_id: '',
				index: 2,
				monitor_id: '',
			},
			Charts: '',
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		monitorId() {
			return this.$route.params.monitorId;
		},
	},
	methods: {
		handleInit() {
			let self = this;
			self.trainData.member_id = self.memberId;
			self.trainData.monitor_id = self.monitorId;
			self.dateData = [];
			self.totalData = [];
			self.highData = [];
			self.lowData = [];
			MemberService.zhaikeQuery(self.trainData).then((data) => {
				self.totalData = data.heartList;
				self.data = data;
				const gap = data.sportTime / data.heartList.length;
				MemberService.getinfo({ member_id: this.memberId }).then((info) => {
					const basis = 220 - info.age;
					const low = basis * 0.6;
					const high = basis * 0.8;
					for (let i = 0; i < data.heartList.length; i++) {
						self.dateData.push(Math.round(i * gap));
						self.highData.push(low);
						self.lowData.push(high - low);
					}
					this.Charts.setOption({
						xAxis: {
							data: self.dateData,
						},
						series: [
							{
								// 根据名字对应到相应的系列
								name: '心率',
								data: self.totalData,
							},
							{
								name: 'high',
								data: self.highData,
							},
							{
								name: 'low',
								data: self.lowData,
							},
						],
					});
				});
			});
		},
		handleChangeTime(value) {
			this.trainData.start = value[0];
			this.trainData.end = value[1];
			this.handleInit();
		},
	},
	mounted() {
		this.handleInit();
		// echarts实例化
		this.Charts = echarts.init(this.$refs.train);
		// 初始化实例
		var options = {
			backgroundColor: '#f1f2f7',
			color: ['#5886e6', '#f3e1e7', '#f3e1e7'],
			xAxis: {
				name: '时间',
				type: 'category',
				data: [],
				boundaryGap: false,
			},
			yAxis: {
				name: '心率',
				type: 'value',
			},
			series: [
				{
					name: '心率',
					data: [],
					type: 'line',
					symbol: 'none',
					smooth: true,
				},
				{
					name: 'high',
					data: [],
					type: 'line',
					stack: 'total',
					areaStyle: {
						color: 'transparent',
					},
					symbol: 'none',
				},
				{
					name: 'low',
					data: [],
					type: 'line',
					stack: 'total',
					areaStyle: {
						color: '#f3e1e7',
					},
					symbol: 'none',
				},
			],
		};
		this.Charts.setOption(options);
	},
};
</script>
<style lang="less" scoped>
.data-contain .table-box {
  margin: 0;
}
.scheme-contain .data-contain {border: none;}
.symbol-box .ivu-btn {margin: 0 0 20px;}
.data-header {
	display: flex;
	justify-content: space-between;
	margin: 0 27px;
	padding: 10px;
	background-color: #f1f2f7;
	font-weight: 700;;
	.data-date {
		color: #fdad00;
	}
	span {
		margin-left: 20px;
	}
}
</style>
